import * as amplitude from "@amplitude/analytics-browser";

// TODO: Review this implementation
const analytics = (amplitudeKey)=>({
  init: function init(user) {
    const isImpersonating = user?.impersonated_nav_info?.show;
    const userId = isImpersonating
      ? user?.pendo_setup?.visitor?.id
      : user?.sub?.split(":").at(-1);

    amplitude.init(amplitudeKey, userId);
    const identifyEvent = new amplitude.Identify();
    identifyEvent.set("client_id", user?.client);
    amplitude.identify(identifyEvent);
  },
  track: amplitude.track.bind(amplitude),
});

export default (object) => {
  const amplitudeKey = document.getElementById("amplitude-key").value;
  if (!amplitudeKey) return;
  object.config.globalProperties.$analytics = analytics(amplitudeKey);
};
